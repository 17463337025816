* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  text-align: center;
  font-family: Arial, Verdana, sans-serif;
  font-size: .8em;
}
#wrapper {
  width: 100%;
  margin: 0 auto;
  text-align: left;
  background: white;
}
@media (min-width: 1140px) {
  #wrapper {
    max-width: 800px;
  }
}
header.head {
  border-bottom: 10px solid black;
  margin-bottom: 20px;
  width: 100%;
}
header.head .mobileMenuContainer {
  color: white;
  min-height: 40px;
  background: black;
  display: block;
}
header.head .mobileMenuContainer .phone {
  color: white;
  display: inline-block;
  width: 80%;
  text-align: left;
  padding-left: 20px;
  line-height: 40px;
  font-size: 1.3em;
  text-decoration: none;
  font-weight: bold;
}
@media (min-width: 1140px) {
  header.head .mobileMenuContainer .phone {
    text-align: right;
    width: 100%;
    padding-right: 20px;
  }
}
header.head .mobileMenuContainer .menuToggle {
  display: inline-block;
  width: 19%;
  color: white;
  text-decoration: none;
  line-height: 40px;
  font-size: 26px;
  text-align: right;
  padding-right: 20px;
}
@media (min-width: 1140px) {
  header.head .mobileMenuContainer .menuToggle {
    display: none;
  }
}
header.head .mobileMenuContainer nav.mobileMenu {
  display: none;
}
header.head .mobileMenuContainer nav.mobileMenu ul {
  list-style: none;
}
header.head .mobileMenuContainer nav.mobileMenu ul li a {
  display: block;
  width: 100%;
  color: white;
  text-decoration: none;
  padding: 8px 20px;
  text-align: center;
}
@media (min-width: 1140px) {
  header.head .logo {
    display: inline-block;
    width: 30%;
  }
}
header.head .logo img {
  height: 125px;
  margin: 15px auto;
  display: block;
}
@media (min-width: 1140px) {
  header.head .logo img {
    display: inline-block;
    width: auto;
  }
}
header.head div.spanner {
  display: block;
}
@media (min-width: 1140px) {
  header.head div.spanner {
    vertical-align: top;
    display: inline-block;
    width: 69%;
    padding-top: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
}
header.head .topimages {
  border-top: 10px solid black;
  height: 103px;
  max-width: 100%;
  background: url('/assets/images/topimages.jpg');
}
header.head .menu {
  display: none;
}
@media (min-width: 1140px) {
  header.head .menu {
    margin-top: -50px;
    display: block;
    width: 69%;
    float: right;
    text-align: center;
  }
}
header.head .menu ul {
  list-style-type: none;
}
header.head .menu ul li {
  display: inline-block;
}
header.head .menu ul li a {
  color: black;
  font-weight: bold;
  float: left;
  line-height: 40px;
  text-decoration: none;
  margin-right: 15px;
}
aside {
  display: none;
  vertical-align: top;
  width: 30%;
}
@media (min-width: 1140px) {
  aside {
    display: inline-block;
  }
}
aside h2 {
  background: black;
  color: white;
  width: 100%;
  padding: 10px 15px;
}
aside .bulllist ul li {
  margin: 5px 20px 3px 20px;
}
article {
  display: inline-block;
  width: 100%;
}
@media (min-width: 1140px) {
  article {
    width: 65%;
  }
}
article p {
  margin: 20px;
}
article h2,
article h3 {
  margin: 10px 20px;
}
section.cta-buttons {
  margin: 20px 10px;
}
section.cta-buttons div {
  display: inline-block;
  width: 100%;
  font-size: 18px;
  height: 70px;
  text-align: center;
}
@media (min-width: 1140px) {
  section.cta-buttons div {
    width: 30%;
  }
}
section.cta-buttons div a {
  color: black;
  font-size: 12px;
}
footer {
  background: black;
  color: white;
  width: 100%;
  padding: 20px;
  text-align: center;
}
footer .logo {
  display: inline-block;
  margin: 5px auto;
}
footer .logo img {
  height: 80px;
}
footer span {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
footer a.manta {
  display: none;
}
@media (min-width: 1140px) {
  footer {
    text-align: left;
  }
  footer .logo {
    display: inline-block;
    width: 29%;
  }
  footer a.manta {
    display: inline-block;
    width: 30%;
    text-align: right;
  }
  footer span {
    display: inline-block;
    text-align: center;
    vertical-align: 50%;
    width: 40%;
  }
}
h2 {
  font-size: 16px;
}
h3 {
  font-size: 14px;
}
h1,
h2,
h3,
h4,
p {
  margin-bottom: 10px;
}
p {
  font-size: 14px;
  text-align: justify;
  margin-bottom: 20px;
}
form {
  margin: 20px;
}
form label {
  font-weight: bold;
  width: 100%;
  display: block;
  height: 30px;
  line-height: 30px;
  margin-top: 5px;
}
form textarea {
  width: 100%;
  height: 100px;
  padding: 5px;
}
input {
  padding: 5px;
}
form > ol > li > input[type="text"] {
  width: 70%;
  height: 30px;
  border: 1px solid #ccc;
  clear: both;
}
form ol li label.error {
  float: right;
  color: red;
  width: auto;
  position: absolute;
}
input[type="submit"] {
  background: black;
  color: white;
  padding: 10px;
  text-transform: uppercase;
  font-size: .9em;
  border: none;
}
#check {
  margin-left: 0;
}
.g-recaptcha {
  margin-top: 15px;
  margin-bottom: 10px;
}
div.spanner {
  margin: 15px auto;
}
div.spanner p {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  margin: 0;
}
div.spanner p span {
  font-size: 16px;
}
span.success {
  width: 100%;
  padding: 5px;
  background: #ccfdcc;
  border: 1px solid #00c300;
  display: block;
  margin-bottom: 5px;
}

@desktop-size: 1140px;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  text-align: center;
  font-family: Arial, Verdana, sans-serif;
  font-size: .8em;
}

#wrapper {
  width: 100%;
  @media (min-width: @desktop-size) {
    max-width: 800px;
  }
  margin: 0 auto;
  text-align: left;
  background: white;
}

header.head {
  border-bottom: 10px solid black;
  margin-bottom: 20px;
  width: 100%;

  .mobileMenuContainer {
    color: white;
    min-height: 40px;
    background: black;
    display: block;

    .phone {
      color: white;
      display: inline-block;
      width: 80%;
      text-align: left;
      padding-left: 20px;
      line-height: 40px;
      font-size: 1.3em;
      text-decoration: none;
      font-weight: bold;
      @media (min-width: @desktop-size) {
        text-align: right;
        width: 100%;
        padding-right: 20px;
      }
    }
    .menuToggle {
      display: inline-block;
      width: 19%;
      color: white;
      text-decoration: none;
      line-height: 40px;
      font-size: 26px;
      text-align: right;
      padding-right: 20px;
      @media (min-width: @desktop-size) {
        display: none;
      }
    }
    nav.mobileMenu {
      display: none;
      ul {
        list-style: none;
        li a {
          display: block;
          width: 100%;
          color: white;
          text-decoration: none;
          padding: 8px 20px;
          text-align: center;
        }
      }
    }
  }
  .logo {
    @media(min-width:@desktop-size) {
      display: inline-block;
      width: 30%;
    }
  }
  .logo img {
    height: 125px;
    margin: 15px auto;
    display: block;
    @media (min-width: @desktop-size) {
      display: inline-block;
      width: auto;
    }
  }
  div.spanner {
    display: block;
    @media(min-width: @desktop-size) {
      vertical-align: top;
      display: inline-block;
      width: 69%;
      padding-top: 20px;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(0,0,0,0.3);
    }
  }
  .topimages {
    border-top: 10px solid black;
    height: 103px;
    max-width: 100%;
    background: url('/assets/images/topimages.jpg');
  }
  .menu {
    @media (min-width: @desktop-size) {
      margin-top: -50px;
      display: block;
      width: 69%;
      float: right;
      text-align: center;
    }
    display: none;
  }
  .menu ul {
    list-style-type: none;
  }
  .menu ul li {
    display: inline-block;
  }
  .menu ul li a {
    color: black;
    font-weight: bold;
    float: left;
    line-height: 40px;
    text-decoration: none;
    margin-right: 15px;
  }
}

aside {
  display: none;
  @media(min-width:@desktop-size) {
    display: inline-block;
  }
  vertical-align: top;
  width: 30%;

  h2 {
    background: black;
    color: white;
    width: 100%;
    padding: 10px 15px;
  }
  .bulllist ul li {
    margin: 5px 20px 3px 20px;
  }
}

article {
  display: inline-block;
  width: 100%;
  @media (min-width: @desktop-size) {
    width: 65%;
  }
  p {
    margin: 20px;
  }
  h2, h3 {
    margin: 10px 20px;
  }
}

section.cta-buttons {
  margin: 20px 10px;
  div {
    display: inline-block;
    width: 100%;
    font-size: 18px;
    height: 70px;
    text-align: center;
    @media (min-width: @desktop-size) {
      width: 30%;
    }
  }
  div a {
    color: black;
    font-size: 12px;
  }
}

footer {
  background: black;
  color: white;
  width: 100%;
  padding: 20px;
  text-align: center;
  .logo {
    display: inline-block;
    img {
      height: 80px;
    }
    margin: 5px auto;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
  a.manta {
    display: none;
  }
  @media (min-width: @desktop-size) {
    text-align: left;
    .logo {
      display: inline-block;
      width: 29%;
    }
    a.manta {
      display: inline-block;
      width: 30%;
      text-align: right;
    }
    span {
      display: inline-block;
      text-align: center;
      vertical-align: 50%;
      width: 40%;
    }
  }
}

h2 {
  font-size: 16px;
}

h3 {
  font-size: 14px;
}

h1, h2, h3, h4, p {
  margin-bottom: 10px;
}
p {
  font-size: 14px;
  text-align: justify;
  margin-bottom: 20px;
}
form {
  margin: 20px;
}

form label {
  font-weight: bold;
  width: 100%;
  display: block;
  height: 30px;
  line-height: 30px;
  margin-top: 5px;
}

form textarea {
  width: 100%;
  height: 100px;
  padding: 5px;
}

input {
  padding: 5px;
}

form > ol > li > input[type="text"] {
  width: 70%;
  height: 30px;
  border: 1px solid #ccc;
  clear: both
}

form ol li label.error {
  float: right;
  color: red;
  width: auto;
  position: absolute;
}

input[type="submit"] {
  background: black;
  color: white;
  padding: 10px;
  text-transform: uppercase;
  font-size: .9em;
  border: none;
}

#check {
  margin-left: 0;
}

.g-recaptcha {
  margin-top: 15px;
  margin-bottom: 10px;
}

div.spanner {
  margin: 15px auto;
}

div.spanner p {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  margin: 0;
}

div.spanner p span {
  font-size: 16px;
}

span.success {
  width: 100%;
  padding: 5px;
  background: rgb(204, 253, 204);
  border: 1px solid rgb(0, 195, 0);
  display: block;
  margin-bottom: 5px
}